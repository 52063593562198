exports.components = {
  "component---src-components-pages-confirm-index-js": () => import("./../../../src/components/pages/confirm/index.js" /* webpackChunkName: "component---src-components-pages-confirm-index-js" */),
  "component---src-components-pages-consumer-index-js": () => import("./../../../src/components/pages/consumer/index.js" /* webpackChunkName: "component---src-components-pages-consumer-index-js" */),
  "component---src-components-pages-contact-us-index-js": () => import("./../../../src/components/pages/contact-us/index.js" /* webpackChunkName: "component---src-components-pages-contact-us-index-js" */),
  "component---src-components-pages-index-index-js": () => import("./../../../src/components/pages/index/index.js" /* webpackChunkName: "component---src-components-pages-index-index-js" */),
  "component---src-components-pages-offer-index-js": () => import("./../../../src/components/pages/offer/index.js" /* webpackChunkName: "component---src-components-pages-offer-index-js" */),
  "component---src-components-pages-opt-in-index-js": () => import("./../../../src/components/pages/opt-in/index.js" /* webpackChunkName: "component---src-components-pages-opt-in-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

